 <template>
  <v-container fluid>
    <h2 class="mt-5 mb-3">
      Items
      <span v-if="pendingWorkers > 0" class="subtitle-2 orange--text">
        <v-progress-circular
            class="ml-6 mr-2"
            indeterminate
            size="20"
            color="primary">
        </v-progress-circular>
        Generating images... ({{pendingWorkers}} left)
      </span>
    </h2>

    <v-row justify="center" class="mt-6 mb-6">
      <v-btn color="primary" v-on:click="openItemDialog()">Add an item</v-btn>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="filteredItems"
        :search="search"
        sort-by="rarity"
        sort-desc
        fixed-header
        hide-default-footer
        disable-pagination>
      <template v-slot:top>
        <v-row>
          <v-col cols="10">
            <v-text-field
                v-model="search"
                label="Search items"
                append-icon="mdi-magnify"
                class="mx-4"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-select
                v-model="searchPackId"
                label="Pack"
                :items="packs"
                class="mx-4"
                item-value="id"
                :item-text="getPackText">
            </v-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.pack="{ item }">
        {{item.pack.name}} {{item.pack.year}}
      </template>
      <template v-slot:item.isInvocable="{ item }">
        <boolean-property :value="item.isInvocable" />
      </template>
      <template v-slot:item.isTradable="{ item }">
        <boolean-property :value="item.isTradable" />
      </template>
      <template v-slot:item.isUpgradable="{ item }">
        <boolean-property :value="item.isUpgradable" />
      </template>
      <template v-slot:item.isGoldable="{ item }">
        <boolean-property :value="item.isGoldable" />
      </template>
      <template v-slot:item.isCraftable="{ item }">
        <boolean-property :value="item.isCraftable" />
      </template>
      <template v-slot:item.isRecyclable="{ item }">
        <boolean-property :value="item.isRecyclable" />
      </template>
      <template v-slot:item.isCounting="{ item }">
        <boolean-property :value="item.isCounting" />
      </template>
      <template v-slot:item.isVisible="{ item }">
        <boolean-property :value="item.isVisible" />
      </template>
      <template v-slot:item.rarity="{ item }">
        <span v-for="index in item.rarity" :key="index">⭐</span>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="primary" text @click="openItemDialog(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="itemDialog" max-width="1300">
      <v-card>
        <v-card-title>
          {{selectedItem.id ? 'Edit' : 'New'}} item

          <span v-if="pendingWorkers > 0" class="subtitle-2 orange--text">
            <v-progress-circular
                class="ml-6 mr-2"
                indeterminate
                size="20"
                color="primary">
            </v-progress-circular>
            Generating images... ({{pendingWorkers}} left)
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon v-on:click="cacheBuster++">mdi-reload</v-icon>
          </v-btn>
          <v-btn color="error" text @click="deleteItem" v-if="selectedItem.id">Delete item</v-btn>
          <v-btn color="primary" text @click="saveItem">Save</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <span>
                <h3 class="mb-3">Preselection</h3>
                <v-row v-if="!selectedItem.id">
                  <v-col v-for="preselect in preselectValues" :key="preselect.title" cols="6">
                   <v-btn class="mb-6" v-on:click="patchValue(preselect.valuePreselect)"> {{ preselect.title }}</v-btn>
                  </v-col>
                </v-row>
              </span>
              
              <v-form
                  ref="itemForm"
                  v-model="validItem">
                <v-text-field
                    v-model="selectedItem.name"
                    label="Name"
                    :rules="[v => !!v || 'Name is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedItem.description"
                    label="Description"
                    :rules="[v => !!v || 'Description is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedItem.genre"
                    label="Genre"
                    :rules="[v => !!v || 'Genre is required']"
                    outlined
                    required></v-text-field>
                <v-text-field
                    v-model="selectedItem.credit"
                    label="Credit"
                    outlined></v-text-field>
                <v-text-field
                    v-model="selectedItem.reference"
                    label="Reference"
                    outlined></v-text-field>
                <v-select
                    v-model="selectedItem.rarity"
                    :items="rarity"
                    :rules="[v => !!v || 'Rarity is required']"
                    v-on:change="rarityChanged()"
                    item-text="label"
                    item-value="value"
                    label="Rarity"
                    outlined
                    required></v-select>
                <v-text-field
                    v-model="selectedItem.identifier"
                    label="Identifier"
                    type="number"
                    :rules="[v => !!v || 'Identifier is required']"
                    outlined
                    required></v-text-field>

                <document-upload
                    v-model="selectedItem.image"></document-upload>
                <v-select v-model="selectedItem.pack"
                          label="Pack"
                          :items="packs"
                          item-value="id"
                          :item-text="getPackText"
                          :rules="[v => !!v || 'Pack is required']"
                          outlined
                          return-object>
                </v-select>
                <v-select v-model="selectedItem.ruleSetTypes"
                          label="Game mode"
                          :items="ruleSetTypes"
                          v-on:change="gameModeChanged()"
                          item-text="label"
                          item-value="value"
                          multiple
                          :rules="[v => !!v || 'Game mode is required']"
                          outlined
                          return-object
                          required>
                </v-select>
                <v-select v-model="selectedItem.theme"
                          label="Theme"
                          :items="themes"
                          item-value="id"
                          item-text="name"
                          :rules="[v => !!v || 'Theme is required']"
                          outlined
                          return-object>
                </v-select>

                <h3 class="mb-3">Configuration</h3>
                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                        v-model="selectedItem.shinyLevels[0]"
                        label="Normal"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                        v-model="selectedItem.shinyLevels[1]"
                        label="Gold"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                        v-model="selectedItem.shinyLevels[2]"
                        label="Chroma"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                        v-model="selectedItem.scores[0]"
                        label="Score"
                        type="number"
                        :rules="[v => (!!v || v === 0) || 'Score is required']"
                        outlined
                        required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-model="selectedItem.scores[1]"
                        label="Score"
                        type="number"
                        :rules="[v => (!!v || v === 0) || 'Score golden is required']"
                        outlined
                        required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        v-model="selectedItem.scores[2]"
                        label="Score"
                        type="number"
                        :rules="[v => (!!v || v === 0) || 'Score chroma is required']"
                        outlined
                        required></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                        v-model="selectedItem.isInvocable"
                        label="Invocable"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isCraftable"
                        label="Craftable"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isGoldable"
                        label="Goldable"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isUpgradable"
                        label="Upgradable"></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox
                        v-model="selectedItem.isVisible"
                        label="Visible"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isRecyclable"
                        label="Recyclable"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isTradable"
                        label="Tradable"></v-checkbox>
                    <v-checkbox
                        v-model="selectedItem.isCounting"
                        label="Counting"></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="8">
              <v-slider v-if="selectedItem.isUpgradable && selectedItem.rarity"
                  class="mt-6 mb-6"
                  min="0"
                  v-model="upgradeLevel"
                  thumb-label="always"
                  :max="selectedItem.rarity"
                  hide-details
                  ticks>
              </v-slider>
              <v-row align="center">
                <v-col cols="6">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[0] + (upgradeLevel > 0 ? '-lv' + upgradeLevel : '') + '?' + cacheBuster"></v-img>
                </v-col>
                <v-col cols="6">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[1] + (upgradeLevel > 0 ? '-lv' + upgradeLevel : '') + '?' + cacheBuster"></v-img>
                </v-col>
                <v-col v-if="selectedItem.ruleSetTypes && selectedItem.ruleSetTypes.includes('HARDCORE')" cols="6">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[0] + (upgradeLevel > 0 ? '-lv' + upgradeLevel : '-hc') + '?' + cacheBuster"></v-img>
                </v-col>
                <v-col v-if="selectedItem.ruleSetTypes && selectedItem.ruleSetTypes.includes('HARDCORE')" cols="6">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[1] + (upgradeLevel > 0 ? '-lv' + upgradeLevel : '-hc') + '?' + cacheBuster"></v-img>
                </v-col>
                <v-col cols="6" v-if="upgradeLevel === 0">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[2] + '?' + cacheBuster"></v-img>
                </v-col>
                <v-col cols="6" v-if="upgradeLevel === 0">
                  <v-img v-if="currentItem.urls" :src="currentItem.urls[3] + '?' + cacheBuster"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackbarColor" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import BooleanProperty from "../components/BooleanProperty";
import DocumentUpload from "../components/DocumentUpload";

export default {
  name: 'Items',
  components: {
    DocumentUpload,
    BooleanProperty
  },

  data() {
      return {
        headers: [
          {
            text: 'Invocable',
            value: 'isInvocable',
            align: 'center'
          },
          {
            text: 'Visible',
            value: 'isVisible',
            align: 'center'
          },
          {
            text: 'Craftable',
            value: 'isCraftable',
            align: 'center'
          },
          {
            text: 'Recyclable',
            value: 'isRecyclable',
            align: 'center'
          },
          {
            text: 'Goldable',
            value: 'isGoldable',
            align: 'center'
          },
          {
            text: 'Upgradable',
            value: 'isUpgradable',
            align: 'center'
          },
          {
            text: 'Tradable',
            value: 'isTradable',
            align: 'center'
          },
          {
            text: 'Counting',
            value: 'isCounting',
            align: 'center'
          },
          {
            text: 'Rarity',
            value: 'rarity'
          },
          {
            text: 'Identifier',
            value: 'identifier'
          },
          {
            text: 'Score',
            value: 'scores[0]'
          },
          {
            text: 'Score golden',
            value: 'scores[1]'
          },
          {
            text: 'Score chroma',
            value: 'scores[2]'
          },
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Genre',
            value: 'genre'
          },
          {
            text: 'Pack',
            value: 'pack'
          },
          {
            text: '',
            value: 'edit',
            filterable: false
          }
        ],
        search: '',
        searchPackId: null,
        items: [],
        packs: [],
        ruleSetTypes: [
          {value: "NORMAL", label: "Normal"},
          {value: "HARDCORE", label: "Hardcore"}
        ],
        themes: [],
        snackbar: false,
        snackbarColor: 'success',
        snackbarText: '',
        itemDialog: false,
        validItem: false,
        currentItem: {},
        selectedItem: {
          scores: {},
          shinyLevels: {},
        },
        upgradeLevel: 0,
        rarity: [
          { value: 1, label: '⭐' },
          { value: 2, label: '⭐⭐' },
          { value: 3, label: '⭐⭐⭐' },
          { value: 4, label: '⭐⭐⭐⭐' },
          { value: 5, label: '⭐⭐⭐⭐⭐' },
          { value: 6, label: '💎' }
        ],
        pendingWorkerInterval: null,
        pendingWorkers: 0,
        cacheBuster: 0,
        preselectValues: [
          {
            title: "Classic card", 
            valuePreselect: {
              ruleSetTypes: ["NORMAL", "HARDCORE"],
              shinyLevels: {
                  0: true,
                  1: true,
                  2: true
              },
              isCounting: true,
              isTradable: true,
              isRecyclable: true,
              isUpgradable: true,
            }
          },
          {
            title: "Event card",
            valuePreselect: {
              ruleSetTypes: ["NORMAL"],
              shinyLevels: {
                  0: true,
                  1: true,
                  2: false
              },
              isCounting: true,
              isCraftable: true,
              isRecyclable: true,
              isGoldable: true,
            }
          },
        ]
      }
    },

  computed: {
    filteredItems() {
      if (!this.searchPackId) {
        return this.items;
      }

      return this.items.filter(item => item.pack.id === this.searchPackId);
    }
  },

  watch: {
    searchPackId() {
      if (this.searchPackId) {
        localStorage.setItem('searchPackId', this.searchPackId)
      } else {
        localStorage.removeItem('searchPackId')
      }
    },

    pendingWorkers(newCount, oldCount) {
      if (newCount === 0 && oldCount === 1) {
        this.cacheBuster++;
      }
    }
  },

  methods: {
    getPackText(pack) {
      return `${pack.name} ${pack.year ? pack.year : ''}`
    },

    rarityChanged() {
      if (!this.selectedItem.rarity) {
        return;
      }

      const scores = [
        [1, 60, 130], [5, 80, 200], [20, 100, 300], [80, 200, 500], [0, 0], [800, 800, 800]
      ];

      axios.get('/item/identifier?rarity=' + this.selectedItem.rarity)
        .then(response => {
          this.selectedItem.identifier = response.data;
          this.selectedItem.scores = {
            0: scores[this.selectedItem.rarity - 1][0],
            1: scores[this.selectedItem.rarity - 1][1],
            2: scores[this.selectedItem.rarity - 1][2]
          }
        });
    },

    gameModeChanged() {
      if (!this.selectedItem.ruleSetTypes) {
        return;
      }
      
      for (let index = 0; index < this.selectedItem.ruleSetTypes.length; index++) {
        if(this.selectedItem.ruleSetTypes[index]?.label){
          this.selectedItem.ruleSetTypes[index] = this.selectedItem.ruleSetTypes[index].value
        }
      }
    },

    deleteItem() {
      this.$confirm('Do you really want to delete this item? All linked user inventories will be deleted.').then(res => {
        if (res) {
          axios.delete('/item/' + this.selectedItem.id)
            .then(() => {
              axios.get('/item')
                .then(response => {
                  this.items = response.data;
                  this.snackbar = true;
                  this.snackbarText = 'Item deleted';
                  this.snackbarColor = 'error';
                  this.itemDialog = false;
                });
            }).catch(() => {
            this.snackbar = true;
            this.snackbarText = 'Error deleting item';
            this.snackbarColor = 'error';
          });
        }
      });
    },

    saveItem() {
      if (!this.$refs.itemForm.validate()) {
        return;
      }

      axios.post('/item', this.selectedItem)
        .then(itemResponse => {
          axios.get('/item')
            .then(response => {
              this.items = response.data;
              this.snackbar = true;
              this.snackbarText = 'Item saved successfully';
              this.snackbarColor = 'success';
              this.selectedItem.id = itemResponse.data.id;
              this.currentItem = JSON.parse(JSON.stringify(itemResponse.data));
            });
        }).catch(() => {
        this.snackbar = true;
        this.snackbarText = 'Error saving item';
        this.snackbarColor = 'error';
      });
    },

    openItemDialog(item) {
      this.itemDialog = true;
      if (!item) {
        item = {
          identifier: null,
          scores: {},
          shinyLevels: {}
        };
        if (this.searchPackId) {
          item.pack = this.packs.find(pack => pack.id === this.searchPackId)
        }
      }
      this.currentItem = item;
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.upgradeLevel = 0;
    },

    patchValue(valuePatch) {
      Object.entries(valuePatch).forEach(([key, value]) => {
        this.selectedItem[key] = value
      });
    }
  },

  mounted() {
    axios.get('/item')
      .then(response => {
        this.items = response.data
      });

    axios.get('/pack')
      .then(response => {
        this.packs = response.data
        this.packs.unshift({
          id: null,
          name: 'All packs'
        })
      });

    axios.get('/theme')
      .then(response => {
        this.themes = response.data
      });

    this.pendingWorkerInterval = setInterval(() => {
      axios.get('/config')
          .then(response => {
            this.pendingWorkers = parseInt(response.data.filter(config => config.configType === 'ITEM_WORKER_PENDING')[0].value);
          });
    }, 1000);

    this.searchPackId = localStorage.searchPackId;
  },

  beforeDestroy() {
    if (this.pendingWorkerInterval) {
      clearInterval(this.pendingWorkerInterval);
    }
  }
}
</script>
