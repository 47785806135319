<template>
  <v-container>
    <h2 class="mt-5 mb-3">Themes</h2>

    <v-select
        v-model="selectedTheme"
        :items="themes"
        item-text="name"
        label="Theme"
        outlined
        return-object
        required></v-select>

    <v-container v-if="selectedTheme">
      <h3 class="text-center mb-4">{{ selectedTheme.name }}</h3>
      <v-row justify="start" align="stretch">
        <v-col cols="1" offset="1" class="text-center font-weight-bold pa-8">Back</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 0</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 1</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 2</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 3</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 4</v-col>
        <v-col cols="1" class="text-center font-weight-bold pa-8">LV 5</v-col>
      </v-row>

      <v-row justify="start" align="stretch" v-for="index in 15" :key="index" class="mb-8">
        <v-col cols="1" v-bind:class="{ 'amber': getShinyLevelFromIndex(index) === 1, 'blue': getShinyLevelFromIndex(index) === 2 }" class="d-flex justify-center align-center">
          <span v-for="index0 in getRarityFromIndex(index)" :key="index0">⭐</span>
        </v-col>

        <v-col cols="1" v-for="(def, index0) in getDefinitions(getRarityFromIndex(index), getShinyLevelFromIndex(index))" :key="index0"
               v-bind:style="{ 'background-color': colorByRarity[getRarityFromIndex(index) - 1] }" class="d-flex justify-center align-center">
          <v-img v-if="findDef(selectedTheme, def)" v-on:click="remove(selectedTheme, def)" :src="findDef(selectedTheme, def).image.url"></v-img>
          <div class="caption text-center theme-upload" v-else>
            <document-upload v-on:input="add(selectedTheme, def, $event)"></document-upload>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style>
.theme-upload .v-input__control {
  display: none;
}
</style>

<script>
import axios from 'axios';
import DocumentUpload from "@/components/DocumentUpload";

export default {
  name: 'Themes',

  components: {
    DocumentUpload
  },

  data() {
    return {
      themes: [],
      selectedTheme: null,
      colorByRarity: ['#6F787D33', '#8B23C033', '#23A3F433', '#108C4D33', '#3BF4BC33'],
    }
  },

  methods: {
    getRarityFromIndex(index) {
      return ((index - 1) % 5) + 1;
    },

    getShinyLevelFromIndex(index) {
      if (index > 10) return 2;
      if (index > 5) return 1;
      return 0;
    },

    getDefinitions(rarity, shinyLevel) {
      let defs = [{
        rarity: rarity,
        upgradeLevel: 0,
        shinyLevel: shinyLevel,
        isFront: false
      }];

      for (let upgradeLevel = 0; upgradeLevel <= rarity; upgradeLevel++) {
        defs.push({
          rarity: rarity,
          upgradeLevel: upgradeLevel,
          shinyLevel: shinyLevel,
          isFront: true
        });
      }

      return defs;
    },

    findDef(theme, def) {
      let currentRuleSetType = localStorage.getItem("ruleSetType") ? localStorage.getItem("ruleSetType") : "NORMAL"
      return theme.definitions.find(
          definition => definition.rarity === def.rarity
          && definition.shinyLevel === def.shinyLevel
          && definition.upgradeLevel === def.upgradeLevel
          && definition.isFront === def.isFront
          && definition.ruleSetType === currentRuleSetType);
    },

    add(theme, def, document) {
      let currentRuleSetType = localStorage.getItem("ruleSetType") ? localStorage.getItem("ruleSetType") : "NORMAL"
      theme.definitions.push({
        image: document,
        rarity: def.rarity,
        upgradeLevel: def.upgradeLevel,
        shinyLevel: def.shinyLevel,
        isFront: def.isFront,
        clip: 'NONE',
        ruleSetType: currentRuleSetType
      });

      this.save(theme);
    },

    remove(theme, def) {
      this.$confirm('Do you really want to delete this theme definition?').then(res => {
        if (res) {
          const definition = this.findDef(theme, def);
          theme.definitions.splice(theme.definitions.indexOf(definition), 1);
          this.save(theme);
        }
      });
    },

    save(theme) {
      axios.post('/theme', theme)
          .then(() => {
            this.load();
          });
    },

    load() {
      axios.get('/theme')
          .then(response => {
            this.themes = response.data
          })
    }
  },

  created() {
    this.load();
  }
}
</script>
