var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"dark","dark":""}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"d-flex primary--text align-self-center font-weight-bold button"},[_vm._v(" ZUNIVERS | ADMIN ")]),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Mode "+_vm._s(_vm.ruleSetType)+" ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":""},on:{"click":function($event){return _vm.switchMode('NORMAL')}}},[_c('v-list-item-title',[_vm._v("Normal")])],1),_c('v-list-item',{attrs:{"to":""},on:{"click":function($event){return _vm.switchMode('HARDCORE')}}},[_c('v-list-item-title',[_vm._v("Hardcore")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" User management ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-title',[_vm._v("Activity")])],1),_c('v-list-item',{attrs:{"to":"/users"}},[_c('v-list-item-title',[_vm._v("Users")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Item management ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/items"}},[_c('v-list-item-title',[_vm._v("Items")])],1),_c('v-list-item',{attrs:{"to":"/fusions"}},[_c('v-list-item-title',[_vm._v("Fusions")])],1),_c('v-list-item',{attrs:{"to":"/packs"}},[_c('v-list-item-title',[_vm._v("Packs")])],1),_c('v-list-item',{attrs:{"to":"/themes"}},[_c('v-list-item-title',[_vm._v("Themes")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Special events ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/events"}},[_c('v-list-item-title',[_vm._v("Events")])],1),_c('v-list-item',{attrs:{"to":"/towers"}},[_c('v-list-item-title',[_vm._v("Towers")])],1),_c('v-list-item',{attrs:{"to":"/tickets"}},[_c('v-list-item-title',[_vm._v("Tickets")])],1),_c('v-list-item',{attrs:{"to":"/claims"}},[_c('v-list-item-title',[_vm._v("Claims")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Orchestrator ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/managers"}},[_c('v-list-item-title',[_vm._v("Managers")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Settings ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/posts"}},[_c('v-list-item-title',[_vm._v("Posts")])],1),_c('v-list-item',{attrs:{"to":"/achievements"}},[_c('v-list-item-title',[_vm._v("Achievements")])],1),_c('v-list-item',{attrs:{"to":"/triggers"}},[_c('v-list-item-title',[_vm._v("Trigger inventories")])],1),_c('v-list-item',{attrs:{"to":"/config"}},[_c('v-list-item-title',[_vm._v("Configuration")])],1)],1)],1),(_vm.profile)?_c('div',{staticClass:"d-flex grey--text align-self-center font-weight-bold ml-4"},[_vm._v(" Logged in as "+_vm._s(_vm.profile.attributes.discord_username[0])+" ")]):_vm._e()],1)],1)],1),_c('v-main',[_c('router-view')],1),_c('v-footer',{staticClass:"mt-6",attrs:{"padless":""}},[_c('v-card',{staticClass:"dark-gray white--text text-center",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-card-text',{staticClass:"white--text"},[_vm._v(" DB v"+_vm._s(_vm.status.dbVersion)+" - API v"+_vm._s(_vm.status.applicationVersion)+" - Web v"+_vm._s(_vm.webVersion)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"white--text"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("ZUnivers Admin")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }